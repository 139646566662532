/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Montserrat", sans-serif;

$displayFont: "Montserrat", sans-serif;

$iconFont: sans-serif;

// Project colors

$primary: #616056;
$secondary: #aaa99f;
$light: #fff;
$medium: #eeeeec;
$dark: #83827a;
$border: #bbb;
$alert: #3f5a24;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		bottom: (align-items:flex-end),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		middle: (align-items:center),
	),
	tiny: (
		width: em(480px, $bpContext), 
		between: (justify-content:space-between),
	),
	small: (
		width: em(640px, $bpContext), 
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (6, 8),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (3, 4, 5, 6, 8),
		reverse: (flex-direction:row-reverse),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (4, 8),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (3, 4),
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

