
// HEADER
//////////////////////////////
.brand{
	background:$light;
	padding:$baseGap;
	margin-top: rem(50px);
	box-shadow: 1px 1px 5px $medium;
	@include breakpoint($break){
		display:none;
	}
}

#header {
	position: relative;
	width: 100%;
	margin-bottom: 3rem;
	body.index &{
		padding: rem(50px) 0;
	}

	@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), false);
	background-size:cover;
	background-position: top left;
	@include breakpoint(medium){
		background-position: top center;
	}
	
	.row{
		// padding:rem(50px) 0 rem(50px);
		.claim{
			font-size: rem(20px);
			line-height: rem(32px);
			margin-bottom:2.5rem;
			color:$primary;
			strong, span{
				display:block;
			}
			span{
				font-size: rem(25px);
				line-height: rem(35px);
			}
			strong{
				color:$alert;
				font-size: rem(32px);
				line-height: rem(40px);
				margin-bottom:1rem;
				font-weight: 700;
			}
			@include breakpoint(tiny){
				max-width:90%;
			}
			@include breakpoint(small){
				max-width:78%;
			}
			@include breakpoint(medium){
				max-width:65%;
			}
		}
		.box{
			background:$light;
			max-width:rem(550px);
			padding:$baseGap;
			color:$primary;
			p{
				font-size: rem(14px);
				line-height: rem(26px);
				span{
					display:block;
					font-size: 1rem;
					line-height: rem(30px);
					margin-bottom: 1rem;
				}
			}
		}
		@include breakpoint(medium){
			padding:rem(150px) 0 rem(100px);
		}
		@include breakpoint(giant){
			padding:rem(250px) 0 rem(200px);
			.claim{
				strong{
					font-size: rem(47px);
					line-height: rem(53px);
				}
			}
			.box{
				padding:2rem;
			}
		}
	}

	@include breakpoint($break) {
		margin-top:rem(133px);
		body.index &{
			min-height:calc(100vh - 133px);
		}
		body:not(.index) &{
			margin-top:rem(209px);
		}
	}

	.branding {
		display: inline-block;
		height: auto;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.teaser{
		overflow:hidden;
		.col{
				padding-left:0;
				padding-right:0;
			}
		@include breakpoint(small){
			.col{
				padding-left:$baseGap;
				padding-right:$baseGap;
			}
		}
		@include breakpoint(large){
			padding-bottom:10rem;
			background:url('../images/layout/bgTeaser.jpg') no-repeat bottom right;
		}
		ul{
			width:100vw;
			@include breakpoint(medium){
				width:100%;
				body:not(.internetExplorer) &{
					display:flex;
					flex-wrap:wrap;
				}
			}
			li{
				margin:0;
				// background:$light;
				max-width:100vw;
				padding:0;
				@include breakpoint(medium, max){
					width:100vw;
				}
				a{
					display:block;
					text-align: center;
					color:$light;
					font-weight: bold;
					background:$alert;
					padding:rem(300px) $baseGap rem(80px) $baseGap;
					text-decoration: none;
					position:relative;
					@include breakpoint(medium, max){
						
						width:100vw;
						body:not(.index) &{
							
							margin:$baseGap 0;
						}
					}
					

					&:before{
						content:'';
						width:rem(190px);
						height:rem(190px);
						background:$light;
						border-radius: 50%;
						position:absolute;
						top:3rem;
						left:50%;
						z-index:2;
						transform:translateX(-50%);
					}
					&:after{
						content:'+';
						display:flex;
						align-items: center;
						justify-content: center;
						font-weight: bold;
						font-size: rem(32px);
						color:$light;
						width:rem(0px);
						height:rem(0px);
						background:$alert;
						border-radius: 50%;
						position:absolute;
						top:rem(63px);
						left:50%;
						z-index:4;
						transform:translateX(-50%);
						transition: all .3s ease-in-out;
					}
					&:hover{
						background:darken($alert, 10%);
						&:after{
							width:rem(170px);
							height:rem(170px);
							transform:translateX(-50%);
						}
					}
				}
				&:first-child{
					a{
						&:before{
							background:$light url('../images/layout/one.jpg');
							body.fachrichtungen &{
								background:$light url('../images/layout/three.jpg');
							}
						}
					}
				}
				&:nth-child(2){
					a{
						&:before{
							background:$light url('../images/layout/two.jpg');
							body.fachrichtungen &{
								background:$light url('../images/layout/four.jpg');
							}
						}
					}
				}
				&:nth-child(3){
					a{
						&:before{
							background:$light url('../images/layout/three.jpg');
						}
					}
				}
				&:nth-child(4){
					a{
						&:before{
							background:$light url('../images/layout/four.jpg');
						}
					}
				}
				&:nth-child(5){
					a{
						&:before{
							background:$light url('../images/layout/five.jpg');
						}
					}
				}
				&:nth-child(6){
					a{
						&:before{
							background:$light url('../images/layout/six.jpg');
						}
					}
				}
				@include breakpoint(medium){
					body:not(.internetExplorer) &{
						flex: 1 1 50%;
					}
					body.internetExplorer &{
						width:50%;
						float:left;
					}
					padding:$baseGap;
					a{
						height:100%;
					}
				}
				@include breakpoint(large){
					body:not(.internetExplorer) &{
						flex: 1 1 33.33%;
					}
					body.internetExplorer &{
						width:33.33%;
					}
				}
			}
		}
	}
	.badge{
		position:relative;
		z-index:4;

		a{
			display:inline-block;
			background:$alert;
			color:$light;
			text-decoration: none;
			position:relative;
			padding:10px 18px 10px 50px;
			transform:translateX(130px) translateY(80px);
			&:before{
				content:'';
				width:rem(130px);
				height:rem(130px);
				border-radius:50%;
				background:$light url('../images/layout/badgeTwo.jpg') no-repeat center center /60% auto;
				position:absolute;
				top:50%;
				border:1px solid $medium;
				left:0;
				transform:translateX(-100px) translateY(-50%);
			}
			&:hover{
				background:darken($alert, 10%);
			}
			@include breakpoint(large, max){
				display:none;
			}
		}

		ul{
			display:inline-block;
			position:relative;
			transform:translateX(120px) translateY(-6rem);
			margin-bottom:0;
			&:before{
				content:'';
				width:rem(160px);
				height:rem(160px);
				border-radius:50%;
				background:$light url('../images/layout/badgeOne.jpg') no-repeat center center /60% auto;
				position:absolute;
				top:50%;
				left:0;
				transform:translateX(-100px) translateY(-50%);
			}
			li{
				margin:3px 0;
				background:$dark;
				padding:10px 18px 10px rem(70px);
				color:$light;
				line-height: rem(24px);
				font-size: rem(16px);
			}
			@include breakpoint(large, max){
				display:block;
				transform:none;
				padding-left:80px;
				margin-bottom:1.5rem;
				&:before{
					border:1px solid $dark;
					transform:translateX(0) translateY(-50%);
				}
				li{
					padding-left:rem(100px);
				}
			}
			@include breakpoint(small, max){
				padding:90px $baseGap $baseGap $baseGap;
				text-align: center;
				background:$medium;
				&:before{
					top:0;
					left:50%;
					transform:translateX(-50%) translateY(-50%);
				}
				li{
					padding:10px 18px;
				}
			}
			@include breakpoint(giant){
				transform:translateX(120px) translateY(-6rem);
				&:before{
					width:rem(200px);
					height:rem(200px);
				}
				li{
					padding:10px 18px 10px rem(130px);
				}
			}
			@include breakpoint(cut){
				transform:translateX(180px) translateY(-6rem);
				&:before{
					width:rem(280px);
					height:rem(280px);
				}
				li{
					padding:10px 18px 10px rem(195px);
				}
			}
		}
	}
	.ginko{
		position: relative;
		@include breakpoint(large){
			.col{
				position: relative;
				z-index:3;
			}
			&:before{
				content:'';
				background:url('../images/layout/ginko.jpg') no-repeat center center;
				width:rem(504px);
				height:rem(469px);
				position:absolute;
				left: rem(-200px);
    			bottom: rem(-230px);
				z-index:1;
			}
		}
	}
	.signature{
		position:relative;
		padding-top:rem(90px);
		&:before{
			background:url('../images/layout/signature.jpg') no-repeat center center /contain;
			content:'';
			width:rem(312px);
			height:rem(83px);
			max-width:100%;
			position:absolute;
			top:0;
			left:0;
		}
	}
	.box{
		background:$dark;
		color:$light;
		padding:2rem $baseGap;
		position:relative;
		h3{
			color:$light;
		}

		a{
			color:$light;
			&:hover{
				opacity:.7;
			}
		}

		&:before{
			content:'';
			width:100%;
			height:100%;
			background:$alert;
			position:absolute;
			left:-2rem;
			top:0;
			z-index:2;
			transform:translateX(-100%);
		}
		&.first{
			&:before{
				background:url('../images/layout/first.jpg') no-repeat center center /cover;
			}
		}
		&.second{
			&:before{
				background:url('../images/layout/second.jpg') no-repeat center center /cover;
			}
		}
		p{
			strong{
				display: block;
				font-size: rem(22px);
				line-height: rem(32px);
			}
		}

		ul{
			li{
				padding:$baseGap;
				&:nth-child(odd){
					background:lighten($dark, 10%);
				}
			}
		}
		@include breakpoint(tiny){
			padding:3rem 2rem;
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.medium{
		background:$medium;
		color:$primary;
		@include breakpoint(large){
			font-size: rem(16px);
		}
		@include breakpoint(huge){
			font-size: rem(18px);
		}
		@extend .distance;
		p{
			margin-bottom: 1.5rem;
		}
		ul{
			@include breakpoint(large, max){
				margin-bottom:$baseGap;
			}
		}
		.details{
			li{
				span{
					display:block;
					span{
						display:inline-block;
					}
					@include breakpoint(tiny){
						display:inline-block;
						padding-right:$baseGap;
					}
					@include breakpoint(medium){
						display:block;
						padding-right:0;
					}
					@include breakpoint(full){
						display:inline-block;
						padding-right:$baseGap;
					}
				}
			}
		}
		.open{
			li{
				span{
					display:block;
					width:100%;
					@include breakpoint(tiny){
						width:auto;
					}
					@include breakpoint(cut){
						display:inline-block;
						width:rem(180px);
					}
					// @include breakpoint(medium){
					// 	display:block;
					// }
					// @include breakpoint(huge){
					// 	display:inline-block;
					// 	width:auto;
					// }
				}
			}
		}
	}
	.googleMaps{
		padding-bottom:rem(350px);
		@include breakpoint(large){
			padding-bottom:rem(650px);
		}
	}
	.dark{
		padding:$baseGap 0;
		background:$alert;
		// @include breakpoint(large, max){
		// 	padding:$baseGap;
		// }
		.row{
			padding:0 $baseGap;
			justify-content: center;
			@include breakpoint(small){
				justify-content: space-between;
			}
			p{
				color:$light;
				padding-left:rem(45px);
				position:relative;
				&:before{
					content:'';
					background:url('../images/layout/fb.png') no-repeat left center /auto 27px;
					width:28px;
					height:rem(28px);
					position:absolute;
					left:0;
					top:50%;
					transform:translateY(-50%);
				}
				@include breakpoint(tiny, max){
					text-align:left;
				}
				@include breakpoint(small){
					margin-top:$baseGap;
					display:block;
					width:100%;
				}
				@include breakpoint(large){
					margin-top:0;
					display:inline-block;
					width:auto;
				}
			}

			a{
				color:$light;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
					opacity:.7;
				}
			}
		}
	}
	@include breakpoint(small, max){
		text-align: center;
	}
}

#footer {
	@include breakpoint(tiny, max) {
		padding-bottom: 4.1rem;
	}
}