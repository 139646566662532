// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: huge; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color:$light;
	font-size: rem(14px);
	@include breakpoint($break){
		color:lighten($alert, 40%);
		padding: 0 rem($baseGap);
		font-size: rem(15px);
	}
	@include breakpoint(huge){
		font-size: rem(16px);
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	@include breakpoint($break, max){
		background:lighten($alert, 10%);
	}
}
%buttonActive {
	// Link Active
	color: $light;
	font-weight: bold;
	@include breakpoint($break, max){
		background:lighten($alert, 10%);
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $dark;
	z-index: 9999999998;

	.details, .open{
		color:$primary;
	}

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						body.schwerpunkte &{
							span.schwerpunkte{
								color: $light;
								font-weight: bold;
								@include breakpoint($break, max){
									background:lighten($alert, 10%);
								}
							}
						}
						@include sub {
							z-index:20;
							background: lighten($medium, 5%);
							&:before{
								padding:$baseGap;
							}
							li{
								border-bottom:$baseBorder;
								a, span.subToggler{
									padding:$baseGap;
									color:$light;
									display:block;
									width:100%;
									&:hover, &.active{
										background:lighten($alert,30%);
										color:$light;
									}
									@include breakpoint($break){
										color:$primary;
									}
								}
								&:last-child{
									border-bottom: none;
								}
								&.active{
									background:lighten($alert,30%);
									span{
										color:$light;
									}
								}
							}

							&:before {
								background-color: darken($navBg, 6%);
							}
							.sub{
								li{
									&.active{
										font-weight: bold;
										a{
											color:$light;
										}
									}
								}
							}
						}
					}
					@include navBack {
						background:lighten($alert, 40%);
						color:$light;
						padding:$baseGap;
						overflow:hidden;
						
						
						span{
							width:auto;
							display:inline-block !important;
						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break, max){
	#navigation{
		nav{
			ul{
				&.navi{
					li{
						&:nth-child(odd){
							background:lighten($dark, 10%);
						}
						a, span{
							display:block !important;
							width:100%;
							padding-left: 1.5rem !important;
						}
						&.hasSub {
							.sub {
								background:$dark;
								li{
									a{
										color:$light;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background:$light;
		box-shadow: 1px 1px 5px $medium;

		.flexBox{
			  margin-left: auto;
			  margin-right: auto;
			  max-width: rem($rowMaxWidth);
			  width: 100%;
			  position: relative;
			  display: flex;
			  flex: 0 1 auto;
			  flex-direction: row;
			  justify-content: space-between;
			  align-items: center;
			  padding:$baseGap;
			  font-size: rem(16px);
		}
		.branding{
			max-width:33%;
		}
		nav {
			background:$alert;
			ul {
				&.navi {
					li {
						&:first-child{
							a{
								padding-left:0;
							}
						}
						&:last-child{
							a{
								padding-right:0;
							}
						}
						&.hasSub {
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}
@include breakpoint(full){
	#navigation{
		.flexBox{
			font-size: rem(18px);
		}
	}
}
// Quickbar Styles
////
#quickbar {
	background-color: $alert;
	color: $light;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	.details{
		li{
			display:inline-block;
			padding-right:$baseGap;
		}
	}

	a{
		color:$light;
		&:hover{
			opacity:.7;
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	li {
		a{
			text-decoration: none !important;
			color:$light;

			&:hover{
				opacity:.7;
			}
			&.active{
				opacity:.7;
				font-weight: bold;
			}
		}
	}
	@include breakpoint(small, max){
		width:100%;
		margin-bottom:1.5rem;
		li{
			a{
				display:block;
				padding:$baseGap;
				border-bottom:1px solid lighten($alert, 10%);
				&:hover, &.active{
					background:lighten($alert, 10%);
				}
			}
		}
	}
	@include breakpoint(small){
		display: flex;
		justify-content: center;
		li{
			margin-right: 1rem;
		}
	}
}